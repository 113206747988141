.o-tour {
  &__item {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &__meta {
    border: solid 3px var(--colourLight);
    padding: var(--spacingHalf);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: var(--spacingOne);
    width: 100px;
  }

  &__content {
    margin-right: var(--spacingOne);
    margin-top: var(--spacingOne);
    flex: 1;
  }

  &__actions {
    margin-top: var(--spacingOne);
    flex: 1;
    flex-basis: 100%;

    @include mq($from: tablet) {
      flex: 0;
      flex-basis: initial;
    }
  }
}
