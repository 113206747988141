.u-section {
  width: 90%;
  position: relative;
  @include mq($from: tabletPortrait) {
    width: 100%;
  }

  &--flex {
    display: flex;
    justify-content: space-between;
  }
}
