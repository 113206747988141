.u-fullheight {
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacingDouble) 0;

  @include mq($from: tabletPortrait) {
    min-height: 100vh;
  }
}
