.c-sony {
  &__text {
    .currentYear {
      margin-right: 6px;
    }

    a {
      text-decoration: underline;
    }
  }
  &__bar {
    margin: 0 6px;
  }
}
