.o-news {
  @include mq($from: tabletPortrait) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacingGrid) var(--spacingGrid);
  }

  &__link {
    opacity: 0;
    transition: opacity .8s ease;
  }

  &__item {
    margin-bottom: var(--spacingGrid);
    position: relative;
    overflow: hidden;

    &__image {
      transition: filter .8s ease;
    }

    &__meta,
    &__title {
      text-shadow: 0 0 2px rgba(0, 0, 0, .9), 0 0 4px rgba(0, 0, 0, .7),
        0 0 6px rgba(0, 0, 0, .5), 0 0 10px rgba(0, 0, 0, .3);
    }

    &__overlay {
      position: absolute;
      background: transparent;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding: var(--spacingTwo);
      z-index: var(--depthAbove);
      transition: background .8s ease;
    }

    &__inner {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      border: 3px solid transparent;
      padding: var(--spacingTwo);
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;

      &__inner {
        transition: opacity .8s ease, filter .4s ease;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: var(--spacingTwo);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    &__presentation {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      flex: 1;
      filter: blur(20px);
      transition: opacity .8s ease, filter .4s ease;
    }

    &:hover {
      .o-news__item__overlay {
        background: var(--colourOverlay);
        //backdrop-filter: blur(14px);
      }
      .o-news__item__image {
        filter: blur(14px);
      }

      .o-news__item__content__inner {
        opacity: 0;
        filter: blur(14px);
      }

      .o-news__item__inner {
        border-color: var(--colourLight);
      }

      .o-news__link {
        opacity: 1;
      }

      .o-news__item__presentation {
        opacity: 1;
        filter: blur(0);
      }
    }
  }
}
