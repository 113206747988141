[class*='u-border-radius--'] {
  overflow: hidden;
}
$small: 4px;
$normal: 6px;

.u-border-radius {
  &--small {
    border-radius: $small;
  }
  &--normal {
    border-radius: $normal;
  }

  &--top-normal {
    border-top-left-radius: $normal;
    border-top-right-radius: $normal;
  }

  &--bottom-normal {
    border-bottom-left-radius: $normal;
    border-bottom-right-radius: $normal;
  }

  &--right-small {
    border: 0;
    border-top-right-radius: $small;
    border-bottom-right-radius: $small;
  }
  &--left-small {
    border: 0;
    border-top-left-radius: $small;
    border-bottom-left-radius: $small;
  }
}
