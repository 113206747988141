.o-brand {
  &__image {
    @include mq($until: tabletPortrait) {
      width: 150px;
    }
  }

  &--mobile {
    padding: var(--spacingBase) 0 0 var(--spacingBase);

    svg {
      width: 150px;
      height: auto;
    }
  }

  &--desktop {
    height: 100vh;
    margin: 0 0 0 var(--spacingDouble);

    @include mq($from: tabletPortrait) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    svg {
      margin: 0;
      max-width: initial;
      height: 95vh;
      height: clamp(100px, 95vh, 95vh);
      width: auto;
    }
  }
}
