/*
    #TABLE
*/

table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border: 0;
  border-bottom: 0;
  margin: 0;
}

tr {
  border: 0;
}

th,
td,
thead,
tbody,
tfoot {
  border: 0;
}
