.o-music {
  &__item {
    position: relative;
    margin-bottom: var(--spacingGrid);

    &__overlay {
      position: absolute;
      background: var(--colourOverlay);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding: var(--spacingTwo);
      z-index: var(--depthAbove);
      backdrop-filter: blur(14px);
      opacity: 0;
      transition: opacity .8s ease;
    }

    &__inner {
      border: 3px solid var(--colourLight);
      height: 100%;
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: var(--spacingTwo);
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__actions {
      width: 100%;
    }

    &:hover {
      .o-music__item__overlay {
        opacity: 1;
      }
    }
  }
}
