/**
  *   layout - FLEX
  */

$directions: top, left, right, bottom;
$spacing: Half, Base, One, Two, Double, Triple, Quad;
$orderOptions: 1, 2, 3;
.u-layout {
  &--flex {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 0 var(--spacingGridNegative);

    &-quad {
      margin: 0 0 0 var(--spacingQuadNegative);

      .u-layout__item {
        padding-left: var(--spacingQuad);
      }
    }
  }

  &--grid {
    display: grid;
  }

  &__space {
    &--center {
      margin: 0 auto;
    }

    @each $space, $value in $spacing {
      @each $direction, $value in $directions {
        $spaceType: #{to-lower-case(#{$space})};
        &--#{$direction}-#{$spaceType} {
          margin-#{$direction}: var(--spacing#{$space});
        }
      }
    }
  }

  &__item {
    padding-left: var(--spacingGrid);
    flex-grow: 1;
  }

  &--flush {
    margin: 0;

    & > .u-layout__item {
      padding-left: 0;
    }
  }

  &--contained {
    max-width: 1128px;
    margin: 0 auto;
  }
}

// Different column sizes
@each $size in $columnSizes {
  $roundsize: floor($size);

  .u-layout__item-#{$roundsize} {
    flex-basis: $size * 1%;
    max-width: $size * 1%;
  }
}

@each $breakpoint, $value in $gridBreakpoints {
  @include mq($until: #{$value}) {
    @each $size in $columnSizes {
      $roundsize: floor($size);

      .u-layout__item-#{$roundsize}#{$breakpoint-separator}#{$breakpoint} {
        flex-basis: $size * 1%;
        max-width: $size * 1%;
      }
    }
  }
}

@each $breakpoint, $value in $gridBreakpoints {
  @include mq($until: #{$value}) {
    @each $orderItem in $orderOptions {
      .u-layout__item--order-#{$orderItem}#{$breakpoint-separator}#{$breakpoint} {
        order: #{$orderItem};
      }
    }
  }
}