.o-aside {
  position: fixed;
  overflow: auto;
  background-color: rgba(0, 0, 0, .8);
  transform: translateX(-100%);
  transition: transform .3s ease;
  height: 100%;
  max-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: left;
  z-index: var(--depthHighest);
  padding: var(--spacingBase);

  @include mq($from: tabletPortrait) {
    justify-content: flex-start;
  }

  &.is-active {
    transform: translateX(0);
  }

  &__burger.o-burger {
    position: absolute;
    top: var(--spacingBase);
    right: var(--spacingOne);
  }
}
