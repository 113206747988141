/**
  *   #HEADINGS
  */

@mixin headings {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}
