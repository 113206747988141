/**
  *   #FULLCLICK
  */

.u-fullClick {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: var(--depthAbove);
  cursor: pointer;
  display: block;
  border: 0;
  background: transparent;
  appearance: none;

  span {
    @include hidden-visually;
  }

  br {
    display: none;
  }

  &__container {
    position: relative;
  }
}
