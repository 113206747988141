/**
  *   #WRAPPER
  */

.u-wrapper {
  max-width: map-get($wrapper, maxWidth);
  width: map-get($wrapper, width);
  margin: 0 auto;
  position: relative;
}
