.c-social {
  &__list {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  /*   &__item {
    opacity: 0;
  } */
  &__link {
    display: block;
    padding: var(--spacingBase);

    .c-icon {
      transition: fill .3s ease;
    }

    &:hover {
      .c-icon {
        fill: var(--colourBrand);
      }
    }
  }
}
