/**
  *   #PAGE
  */

html {
  font-family: map-get($fontDefaults, sansSerif);
  font-size: em(16);
  line-height: map-get($fontDefaults, line-height);
  overflow-y: scroll;
  min-height: 100%;
  background-color: var(--colourBackground);
  color: var(--colourContent);
  font-weight: 400;
  min-height: -webkit-fill-available;
}

body {
  min-height: calc(var(--vh, 1vh) * 100);

  @include mq($from: tabletPortrait) {
    min-height: 100vh;
  }
}
