.o-video {
  padding-bottom: 10vh;
  overflow: hidden;

  .flickity-viewport {
    overflow: visible;
  }

  &__title {
    font-size: var(--fontSizeWatch);
  }

  &__overlay {
    position: absolute;
    background: var(--colourOverlay);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: var(--spacingTwo);
    z-index: var(--depthAbove);
    backdrop-filter: blur(14px);
    opacity: 0;
    transition: opacity .8s ease;

    &__inner {
      border: 3px solid var(--colourLight);
      height: 100%;
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: var(--spacingTwo);
    }
  }

  &__item {
    margin: 0 var(--spacingHalf);
    flex-shrink: 0;
    width: calc(1vh * 18);
    display: block;
    transition: width .5s ease, top .5s ease;
    opacity: .6;

    @include mq($from: mobile) {
      width: calc(1vh * 26);
      margin: 0 var(--spacingOne);
    }

    &.is-selected {
      opacity: 1;

      .o-video__overlay {
        opacity: 1;
      }
    }

    &::before {
      content: '';
      padding-top: 60vh;
      display: block;
      height: 0;
    }

    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(7) {
      top: calc(.03333 * var(--vh, 1vh) * 100);
    }

    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(9) {
      top: calc(.07222 * var(--vh, 1vh) * 100);
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    background-position: center center;
    background-size: cover;
    img {
      height: 100%;
      width: auto;
      max-width: initial;
    }
  }

  &__frame {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 810%;
    }
  }
}
