.o-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacingGrid);

  @include mq($from: mobile) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include mq($from: tabletPortrait) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include mq($from: tablet) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  &__item {
    cursor: pointer;
    &--small,
    &--large {
      //padding-top: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    &--small {
      grid-column-end: span 1;
      grid-row-end: span 1;
    }
    &--large {
      grid-column-end: span 2;
      grid-row-end: span 2;
    }

    &--blank {
      @include mq($until: mobile) {
        display: none;
      }
    }
  }
}
