.o-book-tour {
  display: grid;
  gap: 2rem;

  @include mq($from: tabletPortrait) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.o-book__dates {
  @include mq($from: tabletPortrait) {
    align-self: start;
    top: 1rem;
    position: sticky;
  }
}

.o-book__text {
  & > * {
    margin: 1rem 0;

    &:first-child {
      margin-top: 0;
    }
  }
}
