.c-personal {
  .o-bedtime {
    &__action {
      text-decoration: none;
      max-width: 250px;
      padding: 0;
      margin: 0;
      font-weight: 400;

      svg {
        width: 30px;
        margin-right: var(--spaceHalf);
      }
    }
  }
}
