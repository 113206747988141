.o-burger {
  position: relative;

  &::after {
    @include pseudoElement;
    height: 31px;
    width: 3px;
    background: var(--colourLight);
    top: 3px;
    left: 9px;
    transition: opacity .3s ease;
  }

  &__button {
    position: relative;
    display: block;
    appearance: none;
    border: 0;
    background: transparent;
    width: 30px;
    height: 35px;
    padding: 0;
    margin: 0;

    &__inner {
      position: relative;
      display: block;
      appearance: none;
      border: 0;
      background: transparent;
      width: 21px;
      height: 35px;

      &::before,
      &::after {
        @include pseudoElement;

        height: 31px;
        width: 3px;
        background: var(--colourLight);
        transition: transform .3s ease;
      }

      &::before {
        top: 1px;
        left: 0;
      }

      &::after {
        top: 0;
        right: 0;
      }
    }
  }

  &.is-active {
    &::after {
      opacity: 0;
    }

    .o-burger__button__inner {
      &::after {
        transform: rotate(45deg);
        top: 0;
        left: 50%;
        right: auto;
      }
      &::before {
        transform: rotate(-45deg);
        top: 0;
        right: auto;
        left: 50%;
      }
    }
  }
}
