.o-footer {
  margin: var(--spacingQuad) 0;

  &__nav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: .3rem;

    a {
      display: block;
    }
    li {
      display: flex;
      gap: .3rem;
      &::after {
        content: '|';
      }
      &:last-child::after,
      &:first-child::after {
        content: '';
        display: none;
      }
    }
  }

  &__copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem;
  }
}

.no-tour-dates {
  display: none;
}
