.u-font {
  &__size {
    &--smallest {
      font-size: var(--fontSizeSmallest);
    }

    &--small {
      font-size: var(--fontSizeSmall);
    }

    &--int {
      font-size: var(--fontSizeInt);
    }

    &--normal {
      font-size: var(--fontSizeNormal);
    }

    &--medium {
      font-size: var(--fontSizeMedium);
    }

    &--large {
      font-size: var(--fontSizeLarge);
    }

    &--xlarge {
      font-size: var(--fontSizeXlarge);
    }

    &--xxlarge {
      font-size: var(--fontSizeXXlarge);
    }

    &--xxxlarge {
      font-size: var(--fontSizeXXXlarge);
    }
  }

  &__lineheight {
    &--tight {
      line-height: .8;
    }
    &--one {
      line-height: 1;
    }
    &--normal {
      line-height: 1.2;
    }
    &--loose {
      line-height: 1.6;
    }
  }

  &__transform {
    &--upper {
      text-transform: uppercase;
    }
  }

  &__colour {
    &--light {
      color: var(--colourLight);
    }
    &--dark {
      color: var(--colourDark);
    }
  }

  &__weight {
    &--thin {
      font-weight: 100;
    }

    &--light {
      font-weight: 300;

      strong {
        font-weight: 600;
        color: var(--colourHover);
      }
    }

    &--regular {
      font-weight: 400;
    }

    &--medium {
      font-weight: 500;
    }

    &--bold {
      font-weight: 700;
    }

    &--black {
      font-weight: 900;
    }
  }

  &__align {
    &--center {
      text-align: center;
      .c-icon__container {
        justify-content: center;
      }
    }
    &--right {
      text-align: right;
      .c-icon__container {
        justify-content: right;
      }
    }
    &--left {
      text-align: left;
      .c-icon__container {
        justify-content: left;
      }
    }
  }

  &__margin {
    &--half {
      margin-bottom: var(--spacingHalf);
    }

    &--one {
      margin-bottom: var(--spacingOne);
    }

    &--double {
      margin-bottom: var(--spacingDouble);
    }

    &--zero {
      margin-bottom: 0;
    }
  }

  &__family {
    &--sans {
      font-family: $sansSerif;
    }
    &--serif {
      font-family: $serif;
    }
    &--anton {
      font-family: $anton;
    }
  }

  &__block {
    display: block;
  }
}
