/* ==========================================================================
   #IMAGES
   ========================================================================== */

/**
 * 1. Offset `alt` text from surrounding copy.
 * 2. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */

img {
  font-style: italic; /* [1] */
  vertical-align: middle; /* [2] */
  height: auto;
}
