.c-page-change {
    &-enter-active {
      transition: all .17s ease;
    }
    &-leave-active {
      transition: all .17s ease;
    }
    &-enter,
    &-leave-to {
      filter: blur(200px);
      opacity: .4;
    }
  }
  