/**
  *   #BUTTONS
  */

button[class] {
  cursor: pointer;
}

.c-button {
  @include link-colors(var(--colourLight), var(--colourDark));

  width: 100%;
  padding: 0 var(--spacingDouble);
  border: 3px solid var(--colourLight);
  display: flex;

  appearance: none;
  margin: 0 0 var(--spacingOne);
  height: 42px;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  background: transparent;
  overflow: hidden;
  transition: background .3s ease, color .3s ease;

  &--small {
    @include mq($from: mobile) {
      max-width: 150px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: var(--colourLight);
  }

  &:disabled {
    background: var(--colourDisabled);
    &::after {
      opacity: 0;
    }
  }

  &:hover {
    &::after {
      opacity: 0;
    }
  }
  /**
  * Fix a Firefox bug whereby `input type='submit'` gains 2px extra padding.
  */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}
