.o-main {
  &__inner {
    &--hero {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-attachment: fixed;
      min-height: calc(var(--vh, 1vh) * 100);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      overflow: hidden;
      position: relative;

      @include mq($until: tabletPortrait) {
        background-image: var(--bg-mobile);
      }
      @include mq($from: tabletPortrait) {
        background-image: var(--bg-desktop);
      }

      @include mq($from: tabletPortrait) {
        flex-direction: row;
      }
    }
  }
}
