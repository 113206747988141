.o-header {
  margin: var(--spacingBase) 0;

  @include mq($from: tabletPortrait) {
    margin: var(--spacingDouble) 0;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
