/**
  *   #COLOURS
  */

:root {
  --colourBase: #070707;
  --colourDark: #070707;
  --colourLight: #cbf9db;
  --colourBackground: #445369;
  --colourContent: #cbf9db;
  --colourDisabled: #e8e9ed;
  --colourLink: #cbf9db;
  --colourHover: #ffffff;
  --colourActive: #cbf9db;
  --colourVisited: #cbf9db;
  --colourErrorBackground: #ff7474;
  --colourErrorText: #f69332;

  --colourOverlay: rgba(0, 0, 0, .6);
  --colourHr: #494949;
}
