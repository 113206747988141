/**
  *   #FONTS
  */

/**
  *   #DEFAULT VARIABLES
  */

$sansSerif: 'Open Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
$serif: TimesNewRoman, 'Times New Roman', Times, Baskerville, Georgia, serif;
$anton: 'Anton', sans-serif;

$fontDefaults: (
  weight: 400,
  line-height: 1,
  sansSerif: $sansSerif,
  serif: $serif
);

/**
  *   #SIZES
  */
:root {
  --fontSizeBase: #{em(16, $type: rem)};
  --fontSizeDefault: #{em(16, $type: rem)};
  --fontSizeButton: #{em(20, $type: rem)};
  --fontSizeH1: #{em(30, $type: rem)};
  --fontSizeH2: #{em(26, $type: rem)};
  --fontSizeH3: #{em(22, $type: rem)};
  --fontSizeH4: #{em(18, $type: rem)};
  --fontSizeH5: #{em(18, $type: rem)};
  --fontSizeH6: #{em(18, $type: rem)};
  --fontSizeNav: #{em(60, $type: rem)};
  --fontSizeSubNav: #{em(60, $type: rem)};
  --fontSizeSmallest: #{em(10, $type: rem)};
  --fontSizeSmall: #{em(12, $type: rem)};
  --fontSizeInt: #{em(14, $type: rem)};
  --fontSizeNormal: #{em(16, $type: rem)};
  --fontSizeMedium: #{em(17, $type: rem)};
  --fontSizeLarge: #{em(18, $type: rem)};
  --fontSizeXlarge: #{em(20, $type: rem)};
  --fontSizeXXlarge: #{em(30, $type: rem)};
  --fontSizeXXXlarge: #{em(30, $type: rem)};
  --fontSizeWatch: #{em(20, $type: rem)};

  @include mq($from: tabletPortrait) {
    --fontSizeNav: #{em(110, $type: rem)};
    --fontSizeSubNav: #{em(20, $type: rem)};
    --fontSizeWatch: #{em(30, $type: rem)};
  }
}

/**
  *   #RE-USEABLE FONT GROUPS
  */

%sanSerif {
  font-family: map-get($fontDefaults, sansSerif);
  font-weight: normal;
}

%serif {
  font-family: map-get($fontDefaults, serif);
  font-weight: normal;
}
