/**
  *   #GRID
  */

.u-grid {
  display: grid;
  &--placeCenter {
    place-items: center;
  }
}
