.c-personal {
  p {
    margin-bottom: var(--spacingOne);
  }

  a {
    text-decoration: underline;
    font-weight: 600;
  }

  blockquote {
    margin-bottom: var(--spacingOne);
    padding: var(--spacingDouble) var(--spacingTriple);
    position: relative;
    &::before {
      @include pseudoElement();

      top: 50%;
      left: 0;
      width: 3px;
      background-color: var(--colourLight);
      transform: translateY(-50%);
      height: 60%;
    }
  }

  &__thumbnail {
    margin-bottom: var(--spacingDouble);

    img {
      display: block;
      margin: 0 auto;
    }
  }

  @include headings() {
    font-weight: 600;
    margin-bottom: var(--spacingDouble);
    font-family: $anton;
  }

  &__bedtime {
    @include mq($until: tabletPortrait) {
      display: flex;
      justify-content: center;
    }
  }
}
