.o-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  z-index: var(--depthAboveAll);
  backface-visibility: hidden;
  visibility: hidden;
  display: grid;
  place-items: center;

  @include mq($from: tabletPortrait) {
  }

  &.is-active {
    visibility: visible;

    .o-modal__content {
      transform: translateY(0);
    }
    .o-modal__inner {
      pointer-events: all;
    }

    & ~ .o-modal__overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  &__overlay {
    background-color: var(--colourOverlay);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--depthAbove);
    transition: opacity .3s ease;
    opacity: 0;
    visibility: hidden;
  }

  &__inner {
    display: grid;
    place-items: center;
    width: 90vw;
    height: 100%;
    pointer-events: none;
  }

  &__content {
    pointer-events: all;
    background-color: var(--colourLight);
    position: relative;
    backface-visibility: hidden;
    padding: var(--spacingHalf);
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform .6s ease;
    transform: translateY(100%);
    width: 100%;

    @include mq($from: tabletPortrait) {
      padding: var(--spacingDouble);
      width: 90%;
    }

    p {
      margin-bottom: var(--spacingDouble);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__actions {
    width: 100%;
  }

  &__avatar {
    border: 3px solid var(--colourLight);
    margin-top: -60px;
    margin-bottom: var(--spacingOne);
  }

  &__medium-width {
    min-width: 300px;
  }

  &__close {
    position: absolute;
    top: var(--spacingOne);
    right: var(--spacingOne);
    background: transparent;
    border: 0;
    appearance: none;
    width: 30px;
    height: 30px;
    z-index: var(--depthAboveAll);
    cursor: pointer;

    &::before,
    &::after {
      @include pseudoElement;

      top: 35%;
      left: 0;
      height: 4px;
      width: 30px;
      background: var(--colourLight);
    }

    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}
