///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.

$inputBorder: 0;

/**
  *   #DEFAULT VARIABLES
  */
$wrapper: (
  maxWidth: 1280px,
  width: 93.5%
);

/**
  *   #RATIOS
  */

$inuit-ratios: (
  '16\\:9': (
    16: 9
  )
);

:root {
  /*
  * #DEFAULT
  */
  --vh: (1);

  /**
  *   #SPACING
  */

  --spacingBase: #{em(12)};
  --spacingOne: #{em(16)};
  --spacingHalf: #{em(6)};
  --spacingQuarter: #{em(3)};
  --spacingTwo: #{em(16)};
  --spacingDouble: #{em(24)};
  --spacingTriple: #{em(36)};
  --spacingQuad: #{em(48)};
  --spacingGrid: #{em(16)};
  --spacingGridNegative: -#{em(16)};
  --spacingQuadNegative: -#{em(48)};

  /**
  *   #DEPTH
  */

  --depthBase: 1;
  --depthBehind: -1;
  --depthZero: 0;
  --depthAbove: 10;
  --depthHigh: 15;
  --depthHigher: 20;
  --depthHighest: 100;
  --depthAboveAll: 150;

  @include mq($from: tabletPortrait) {
    --spacingTwo: #{em(32)};
  }
}

/**
  *   #INPUT TYPES
  */

$inputTypes: 'color', 'date', 'datetime', 'datetime-local', 'email', 'file',
  'hidden', 'image', 'month', 'number', 'password', 'range', 'search', 'tel',
  'text', 'time', 'url', 'week';
