/**
  *   #SKIP TO
  */

.u-skipto {
  position: absolute;
  width: 100%;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
  display: block;

  &:focus,
  &:active {
    position: static;
    clip: unset;
    -webkit-clip-path: none;
    clip-path: none;
    background: var(--colourLight);
    padding: var(--spacingBase);
    display: block;
  }

  &:hover {
    text-decoration: underline;
    color: var(--colourDark);
  }
}
