/**
  *   #ACCORDION
  */

.c-accordion {
  max-width: 720px;
  width: 90%;
  margin: 0 auto;

  &__title {
    width: 100%;
    border: 0;
    position: relative;
    cursor: pointer;
    display: block;
    margin-bottom: var(--spacingOne);
    font-weight: 400;
    display: flex;
    align-items: center;
    transition: color .3s ease;

    &::after {
      margin-left: var(--spacingBase);
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 9px solid transparent;
      border-left: 9px solid var(--colourHr);
      border-bottom: 9px solid transparent;
      transition: transform .3s ease;
    }

    &:hover {
      color: var(--colourHover);
    }

    &.is-active {
      &::after {
        transform: rotate(90deg);
      }
    }
  }

  &__content {
    transition: max-height .5s cubic-bezier(0, 1, 0, 1);
    max-height: 0;
    overflow: hidden;

    &__inner {
      padding: var(--spacingQuad) 0 0;
    }

    p {
      margin-bottom: var(--spacingOne);
    }

    a {
      text-decoration: underline;
      font-weight: 600;
    }

    &.is-active {
      max-height: 9000px;
      transition: max-height 1s ease-in-out;
    }
  }
}
