/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Redefine all of our basic heading styles against utility classes so as to
 * allow for double stranded heading hierarchy, e.g. we semantically need an H2,
 * but we want it to be sized like an H1:
 *
 *   <h2 class="u-h1"></h2>
 *
 */

.u-h1 {
  font-size: var(--fontSizeH1);
}

.u-h2 {
  font-size: var(--fontSizeH2);
}

.u-h3 {
  font-size: var(--fontSizeH3);
}

.u-h4 {
  font-size: var(--fontSizeH4);
}

.u-h5 {
  font-size: var(--fontSizeH5);
}

.u-h6 {
  font-size: var(--fontSizeH6);
}
