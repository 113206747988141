/**
  *   #HEADINGS
  */

@include headings() {
  font-weight: map-get($fontDefaults, weight);
}

h1 {
  font-size: var(--fontSizeH1);
}

h2 {
  font-size: var(--fontSizeH2);
}

h3 {
  font-size: var(--fontSizeH3);
}

h4 {
  font-size: var(--fontSizeH4);
}

h5 {
  font-size: var(--fontSizeH5);
}

h6 {
  font-size: var(--fontSizeH6);
}
