/**
  *   #LINKS
  */

a {
  @include link-colors(var(--colourLink), var(--colourHover), var(--colourLink), var(--colourVisited), var(--colourActive));

  text-decoration: none;
  transition: color .3s ease;
}
