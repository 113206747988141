/**
  *   #PRE/CODE
  */

pre,
code {
  line-height: map-get($fontDefaults, line-height);
  overflow: hidden;
  padding: 0 var(--spacingBase);
  margin: 0 0 var(--spacingBase);
  vertical-align: middle;
}
