.o-nav {
  list-style: none;

  &__item {
    display: block;
    &:last-child {
      .o-nav__link {
        padding-right: 0;
      }
    }
  }

  &__main {
    display: flex;
    justify-content: flex-end;

    .o-nav__link {
      span {
        position: relative;
        display: block;
        padding: var(--spacingQuarter) 0;

        &::before,
        &::after {
          @include pseudoElement;

          width: 0;
          height: 1px;
          background: var(--colourHover);
          transition: width .3s ease;
        }

        &::before {
          top: 0;
          left: 55%;
        }
        &::after {
          bottom: 0;
          right: 55%;
        }
      }

      &:hover {
        span {
          &::before,
          &::after {
            width: 55%;
          }
        }
      }
    }

    .is-active .o-nav__link {
      color: var(--colourHover);
      span {
        &::before,
        &::after {
          width: 55%;
        }
      }
    }
  }

  &__link {
    font-family: $anton;
    font-size: var(--fontSizeSubNav);
    line-height: 1.1;
    text-transform: uppercase;
    display: block;
    padding: 0 var(--spacingOne);
  }

  &.o-nav__large {
    margin: var(--spacingBase);
    text-align: right;
    @include mq($from: tabletPortrait) {
      text-align: left;
    }

    @include mq($from: tabletPortrait) {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 3vw;
      display: flex;
      align-items: flex-end;
    }

    @include mq($from: tablet) {
      bottom: .8vw;
    }

    .o-nav__link {
      font-size: 4em;
      font-size: clamp(em(50), 6vw, em(130));
      opacity: .7;
      transition: filter .6s ease, opacity .3s ease;
      padding: 0;
      display: block;

      @include mq($from: tabletPortrait) {
        text-orientation: sideways-right;
        writing-mode: vertical-rl;
        direction: rtl;
        transform: rotate(180deg);
        min-width: 6vw;
      }

      &:hover {
        opacity: 1;
        filter: blur(6px);
        transform: stretch(270deg);
      }
    }

    .o-nav__item.is-active {
      .o-nav__link {
        opacity: 1;
      }
    }
  }
}
