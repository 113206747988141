/**
  * #HORIZONTAL RULE
  */

hr {
  border: none;
  width: 90%;
  max-width: 600px;
  display: block;
  margin: var(--spacingQuad) auto;
  height: 1px;
  background: var(--colourHr);
}
